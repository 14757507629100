body {
  background-color: #ffffff;
}

@media (max-width:2400px) {
  .second_glare{
    display: none;
  }
}

@media (max-width: 768px) {
  .verified-icon {
    display: none;
  }
  #wrap{
    padding:0px !important;
  }
  .css-10gqqxt {
    position: fixed;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    top: 90% !important;
    left: 70% !important;
    width: 180px !important;
    height: 180px !important;
    -webkit-filter: blur(70px)!important;
    filter: blur(70px) !important;
    background: radial-gradient(circle at 50% 50%, rgba(76, 0, 255, 1), #4c00ff00);
    opacity: .7;
  }
}

@media (min-width: 768px) {
  .verified-icon-xs{
    display: none;
  }
}

a{
  text-decoration: none;
  color:inherit;
}

.grecaptcha-badge{
  position: absolute !important;
  width:1px !important;
  right: 0px !important;
}

.certs-icon {
 font-size: 5em !important;
}

.certs-card{
  padding: 40px 20px 20px 20px;
}

#wrap {
  backdrop-filter: blur(50px) saturate(1.5);
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 25px 35px rgba(0, 0, 0, 0.2);
  padding: 35px;
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: dimgrey;
  text-align: center;
  margin-top: 30px;
  min-height: 500px;
}

#wrap2 {
  backdrop-filter: blur(50px) saturate(1.5);
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  /*box-shadow: 0 25px 35px rgba(0, 0, 0, 0.2);*/
  padding: 35px;
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: dimgrey;
  text-align: center;
  min-height: 500px;
}


.profile-card {
  backdrop-filter: blur(50px) saturate(1.1);
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  padding: 50px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

#profileImage {
  border-radius: 50%;
  width: 100px;
  height: 100px;
}

.profile-card h2 {
  margin-top: 10px;
}

.profile-card .stat {
  margin-bottom: 10px;
}

.profile-card .stat .label {
  font-weight: bold;
}

.css-1ueqzyg {
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  top: 25%;
  left: 0%;
  width: 400px;
  height: 400px;
  -webkit-filter: blur(80px);
  filter: blur(100px);
  background: radial-gradient(circle at 50% 50%, #444bf7, #444bf700);
  opacity: 0.7;
}
.css-10gqqxt {
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  top: 80%;
  left: 90%;
  width: 300px;
  height: 300px;
  -webkit-filter: blur(80px);
  filter: blur(80px);
  background: radial-gradient(circle at 50% 50%, rgba(76, 0, 255, 1), #4c00ff00);
  opacity: .7;
}